<template>
  <div>
    <b-card class="mt-6" v-if="pending">
      
      <div class="mx-auto d-flex justify-content-center mb-3">
        <memlist-spinner />
      </div>
    </b-card>
    
    <b-card v-else class="mt-6" header-border-variant="white" no-body>
      <template #header>
          <h5 class="mb-0">{{$t('PAGES.DASHBOARD.RENEWALS')}}</h5>
      </template>
      <div class="custom-card-body-2-column">
          <div class="title">
              <div class="d-flex flex-column">
                  <span class="sales-title-text">{{ numRenewsCompletedToThisPeriod }}</span>
              </div>
              <img src="assets/images/iconbox_bg.png" alt="bg" />
          </div>
          <div class="data">
              <div class="member-lists">
                  <div class="data-member-icon mb-3">
                      <i class="member-icon-1"></i><span>{{$t('PAGES.DASHBOARD.TOTAL')}}</span>
                      <span class="sales-title-text"
                      >: {{ numRenewsFromPreviousPeriod }}</span
                      >
                  </div>
                  <div class="data-member-icon mb-3">
                      <i class="member-icon-2"></i><span>{{$t('PAGES.DASHBOARD.DONE')}}</span>
                      <span class="sales-title-text"
                      >: {{ numRenewsCompletedToThisPeriod }}</span
                      >
                  </div>
                  <div class="data-member-icon mb-3">
                      <i class="member-icon-3"></i><span>{{$t('PAGES.DASHBOARD.NOTDONE')}}</span>
                      <span class="sales-title-text"
                      >: {{ numRenewsFromPreviousPeriod - numRenewsCompletedToThisPeriod }}</span
                      >
                  </div>
              </div>
          </div>
      </div>
    </b-card>
  </div>
    
</template>

<script>
import axios from 'axios';
import dayjs from 'dayjs';
import { mapGetters } from 'vuex';

export default {
  name: 'DashboardRenewSummaryCard',
  components: {

  },
  props: ['period_id'],
  data() {
    return {
      year: 2022,
      period: null,
      numRenewsCompletedToThisPeriod: 0,
      numRenewsFromPreviousPeriod: 0,
      pending: true
    };
  },
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId','isAuthenticated','initComplete']),
  },
  mounted() {
    this.loadData(this.currentPeriodId);
  },
  watch: {
    currentCompanyId() {
    
    },
    currentPeriodId(newValue, oldValue) {
      if (newValue != -1) {
        this.loadData(newValue);
      }
    }
  },
  methods: {
    runLoadAgain() {
      const that = this;
      setTimeout(function(){
        that.loadData(that.currentPeriodId);
      }, 1000);
    },
    async loadData(period_id) {

      if (!this.isAuthenticated) {
        this.runLoadAgain();
        return;
      }
      if (!this.currentCompanyId) {
        this.runLoadAgain();
        return;
      }
      
      axios
        .get(`/member_renew/stats/${this.currentCompanyId}/${period_id}`)
        .then(res => {
          if(res.status === 200) {
            this.year = dayjs(res.data.period.from).format('YYYY');

            this.numRenewsCompletedToThisPeriod = res.data.num_done;
            this.numRenewsFromPreviousPeriod = res.data.num_total;

            this.pending = false;
          }
          else if (res.status === 404) {
            this.pending = false;
            this.numRenewsCompletedToThisPeriod = 0;
            this.numRenewsFromPreviousPeriod = 0;

            return;
          }
          else {
            this.runLoadAgain();
          }
        })
        .catch(err => {
          console.error(err);
          this.runLoadAgain();
        });
    }
  }
};
</script>

<style lang="scss" scoped>
  
    .custom-card-body-2-column {
      display: flex;
      flex-direction: row;
      flex-grow: 1;
      width: 100%;
      height: 100%;
      padding: 2rem 0rem;
      .title {
        flex: 1;
        position: relative;
        font-size: 2.2rem;
        font-weight: 600;
        color: #595d63;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          opacity: 0.08;
          position: absolute;
          z-index: 0;
          width: 120px;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
      .data {
        flex: 1;
        display: flex;
        align-items: center;
        margin-left: 3rem;
        .member-icon-1 {
          display: inline-block;
          width: 0.45rem;
          height: 0.45rem;
          flex: 0 0 0.45rem;
          border-radius: 100%;
          margin-bottom: 0.12rem;
          margin-right: 0.8rem;
          background-color: #93a2dd !important;
        }
        .member-icon-2 {
          display: inline-block;
          width: 0.45rem;
          height: 0.45rem;
          flex: 0 0 0.45rem;
          border-radius: 100%;
          margin-bottom: 0.12rem;
          margin-right: 0.8rem;
          background-color: #5d78ff !important;
        }
        .member-icon-3 {
          display: inline-block;
          width: 0.45rem;
          height: 0.45rem;
          flex: 0 0 0.45rem;
          border-radius: 100%;
          margin-bottom: 0.12rem;
          margin-right: 0.8rem;
          background-color: #646c9a !important;
        }
      }
    }
    .membership-acquisition {
      .gender-girl-line {
        width: 15px;
        height: 5px;
        border-radius: 3px;
        background-color: #5d78ff !important;
      }
      .gender-boy-line {
        width: 15px;
        height: 5px;
        border-radius: 3px;
        background-color: #93a2dd !important;
      }
      .gender-other-line {
        width: 15px;
        height: 5px;
        border-radius: 3px;
        background-color: #646c9a !important;
      }
    }
  
</style>
